
          @use "sass:math";
          @import "~@/assets/scss/variables.scss";
          @import "~@/assets/scss/mixins/misc.scss";
        

.hm-group {
  width: 100%;
  margin: 8px 0 0 0!important;
  padding: 24px;
  border-radius: 12px;
  border: 1px solid #E8E8EE;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-wrap: wrap;
  &__title {
    margin-left: 12px;
  }
  @include for-size(phone-portrait-down) {
    padding: 7px 12px;
  }
  ::v-deep .hm-form__element {
    margin: 10px 0;
    &:first-child {
      margin-top: 0;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
  &--horizontal {
    padding: 14px;
    flex-direction: row;
    @include for-size(phone-portrait-down) {
      padding: 7px;
    }
    ::v-deep .hm-form__element {
      margin: 10px;
      flex: 1 1 auto;
      min-width: calc(33.33% - 20px);
      @include for-size(phone-portrait-down) {
        margin: 5px;
        min-width: calc(33.33% - 10px);
      }
    }
  }
  &--added {
    margin-top: 20px!important;
  }
  &--without-border {
    border: none;
    padding: 0;
    margin: 0 !important;
  }
}
.hm-group--horizontal.hm-group--without-border {
  width: calc(100% + 20px);
  margin: 8px -10px 0!important;
}
.add-group, .remove-group {
  margin: 20px 0 0 0;
}
.remove-group {
  margin-right: 20px;
}
